/* table {
    border-collapse: collapse;
  } */
  thead {
    background: #ccc; 
    border: none;
   border-radius: 10px;
 }
 
 th, td {
   /* border: 1px solid #ccc; */
   padding: 8px;
   
 }
 
 /* tr:nth-child(even) {
   background: #efefef;
   
 } */
 tbody td{
   height: 50px;
 }

 .addcard{
   height:530px !important;
   max-height:550px !important;
   overflow-y: auto;
  
}
* > .intro-x:nth-child(1) {
 z-index: 49;
 opacity: 0;
 position: relative;
 transform: translate(50px);
 animation: 0.4s intro-x-animation ease-in-out 0.33333s;
 animation-fill-mode: forwards;
 animation-delay: 0.5s;
}
* > .intro-x:nth-child(2) {
 z-index: 49;
 opacity: 0;
 position: relative;
 transform: translate(50px);
 animation: 0.4s intro-x-animation ease-in-out 0.33333s;
 animation-fill-mode: forwards;
 animation-delay: 0.5s;
}
* > .intro-x:nth-child(3) {
 z-index: 49;
 opacity: 0;
 position: relative;
 transform: translate(50px);
 animation: 0.4s intro-x-animation ease-in-out 0.33333s;
 animation-fill-mode: forwards;
 animation-delay: 0.5s;
}
* > .intro-x:nth-child(4) {
 z-index: 49;
 opacity: 0;
 position: relative;
 transform: translate(50px);
 animation: 0.4s intro-x-animation ease-in-out 0.33333s;
 animation-fill-mode: forwards;
 animation-delay: 0.5s;
}
* > .intro-x:nth-child(5) {
 z-index: 49;
 opacity: 0;
 position: relative;
 transform: translate(50px);
 animation: 0.4s intro-x-animation ease-in-out 0.33333s;
 animation-fill-mode: forwards;
 animation-delay: 0.5s;
}

* > .-intro-y:nth-child(1) {
 z-index: 19;
 opacity: 0;
 position: relative;
 transform: translateY(-50px);
 animation: 0.4s intro-y-animation ease-in-out 0.33333s;
 animation-fill-mode: forwards;
 animation-delay: 1.1s;
}
* > .-intro-y:nth-child(2) {
 z-index: 19;
 opacity: 0;
 position: relative;
 transform: translateY(-50px);
 animation: 0.4s intro-y-animation ease-in-out 0.33333s;
 animation-fill-mode: forwards;
 animation-delay: 3.1s;
}
* > .-intro-y:nth-child(3) {
 z-index: 19;
 opacity: 0;
 position: relative;
 transform: translateY(-50px);
 animation: 0.4s intro-y-animation ease-in-out 0.33333s;
 animation-fill-mode: forwards;
 animation-delay: 3.1s;
}
@keyframes intro-x-animation {
 to {
   opacity: 1;
   transform: translate(0);
 }
}

@keyframes intro-y-animation {
 to {
   opacity: 1;
   transform: translateY(0);
 }
}